import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PublicRoute } from "../components/PublicRoute/PublicRoute";

import { App } from "./App";
import { Home } from "./Home";
import { DeodorantGyH } from "./Deodorant";
import { Glister } from "./Glister";
import { Lecithin } from "./Lecithin";
import { SkinNutritionCleaner } from "./SkinNutritionCleaner";
import { BComplex } from "./BComplex";

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
        </Route>
        <Route path="/desodorante-vegano" element={<DeodorantGyH />} />
        <Route path="/cuidado-dental-glister" element={<Glister />} />
        <Route path="/encapsulador-grasas" element={<Lecithin />} />
        <Route path="/limpiador-natural" element={<SkinNutritionCleaner />} />
        <Route path="/energia" element={<BComplex />} />
      </Routes>
    </BrowserRouter>
  );
};
