import styled from "styled-components";
import { theme } from "../../styles/theme";
import backImage from "../../assets/images/backgroundCleaner.png";

export const ImgBackgroundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  padding: ${({ p }) => p || "0 50px 0 50px"};
  background: url(${backImage}) no-repeat;
  background-position: center;
  background-size: cover;
  align-items: center;
`;

export const ImgWrapper = styled.div`
  height: ${({ height }) => height || "100px"};
  width: ${({ width }) => width || "100px"};
  background: ${({ background }) =>
    background
      ? `url(${background}) no-repeat`
      : `${theme.colors.green} 0% 0% no-repeat padding-box`};
  background-position: center center;
  background-size: cover;
  border-radius: ${({ radius }) => radius || null};
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ border }) => border || "5px solid #000"};
  border-color: ${({ borderColor }) => borderColor || "transparent"};

  img {
    width: 100%;
    height: 100%;
    border-radius: ${({ radius }) => radius || null};
    object-fit: cover;
  }
`;
