import { createSlice } from "@reduxjs/toolkit";
import { axiosClient } from "../../services/axiosInstance";

export const slice = createSlice({
  name: "clinics",
  initialState: {
    selectedClinic: {},
  },
  reducers: {
    setSelectedClinic: (state, action) => {
      return {
        ...state,
        selectedClinic: action.payload,
      };
    },
  },
});

export default slice.reducer;

export const { setSelectedClinic } = slice.actions;

export const setSelectedClinicInformation = (data) => async (dispatch) => {
  dispatch(setSelectedClinic(data));
};
