import { useMediaQuery } from "react-responsive";
import { Container, Row, Text, Div, Col, Card } from "../../styles/Common";
import { Link } from "../../components/Link";
import { Button } from "../../components/Button";
import { theme } from "../../styles/theme";
import logo from "../../assets/images/logo.png";
import { WhatsAppOutlined } from "@ant-design/icons";
import { ImgWrapper } from "./styles";
import gyh from "../../assets/images/gyh.png";
import naturalProducts from "../../assets/images/naturalProducts.png";
import satinique from "../../assets/images/satinique.png";
import skinNutrition from "../../assets/images/skinNutrition.jpg";
import home from "../../assets/images/homeUSA.jpg";
import backgroundHome from "../../assets/images/background.png";

export const Home = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: 768px)`,
  });

  const openWhatsappChat = (phoneNumber) => {
    window.open(`https://wa.me/${phoneNumber}`, "_blank");
  };
  return isMobile ? (
    <Container>
      <Col
        width="100%"
        p="0 0 20px 20px"
        radius="0 0 150px 0"
        justify="center"
        style={{
          flex: 1,
          background: `linear-gradient(to bottom, ${theme.colors.green200},${theme.colors.green50} )`,
        }}
      >
        <Row align="center" m="50px 0 20px 0">
          <img alt="logo" src={logo} width="100px" height="100px" />
          <Col>
            <Text
              color={theme.colors.green500}
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h2}
            >
              Verde Vital
            </Text>
            <Text
              color={theme.colors.green}
              weight={theme.fonts.weight.medium}
              size={theme.fonts.size.h5}
            >
              Tú mejor aliado
            </Text>
          </Col>
        </Row>
        <Row gap="10px">
          <Text
            color={theme.colors.green300}
            weight={theme.fonts.weight.extrabold}
            size={theme.fonts.size.h4}
          >
            PRODUCTOS
          </Text>
          <Text
            color={theme.colors.green500}
            weight={theme.fonts.weight.extrabold}
            size={theme.fonts.size.h4}
          >
            NATURALES
          </Text>
        </Row>
        <Row gap="10px" align="center">
          <Text
            color={theme.colors.green300}
            weight={theme.fonts.weight.extrabold}
            size={theme.fonts.size.h4}
          >
            PARA EL
          </Text>
          <Text
            color={theme.colors.green500}
            weight={theme.fonts.weight.extrabold}
            size={theme.fonts.size.h3}
          >
            DÍA A DÍA
          </Text>
        </Row>
        <Row>
          <Button
            onClick={() => openWhatsappChat("+573164231214")}
            width="280px"
            m="10px 0 0 0"
            background={theme.colors.green300}
          >
            <Row gap="20px" justify="center">
              <WhatsAppOutlined
                style={{
                  color: theme.colors.white,
                }}
              />
              <Text
                color={theme.colors.white}
                weight={theme.fonts.weight.regular}
                size={theme.fonts.size.h5}
              >
                Hablar por Whatsapp
              </Text>
            </Row>
          </Button>
        </Row>
      </Col>
      <Col m="20px" align="center" style={{ flex: 1 }}>
        <Col>
          <Text
            color={theme.colors.green}
            weight={theme.fonts.weight.bold}
            size={theme.fonts.size.h2}
          >
            ¡Bienvenido a Verde Vital!
          </Text>
          <Text size={theme.fonts.size.h4}>
            ¡Estamos emocionados de tenerte aquí! En Verde Vital nos dedicamos a
            ofrecerte productos naturales de alta calidad para mejorar tu
            bienestar y estilo de vida.
          </Text>
        </Col>
        <Row width="100%" justify="center" m="10px 0 0 0">
          <ImgWrapper
            background={naturalProducts}
            width="400px"
            height="250px"
            radius="0 50px 0 50px"
          />
        </Row>
      </Col>
      <Col
        p="10px 50px 50px 50px"
        align="center"
        style={{
          background: `linear-gradient(to bottom, ${theme.colors.green200},${theme.colors.green50} )`,
          gap: "20px",
        }}
      >
        <Row justify="center" m="0 0 20px">
          <Text
            color={theme.colors.green500}
            weight={theme.fonts.weight.bold}
            size={theme.fonts.size.h2}
          >
            Encuentra...
          </Text>
        </Row>
        <Card p="10px 20px 10px 20px" align="center">
          <ImgWrapper
            background={gyh}
            width="200px"
            height="200px"
            radius="50%"
          />
          <Text
            color={theme.colors.green300}
            weight={theme.fonts.weight.bold}
            size={theme.fonts.size.h2}
          >
            Cuidado Personal
          </Text>
        </Card>
        <Card p="20px" align="center">
          <ImgWrapper
            background={satinique}
            width="200px"
            height="200px"
            radius="50%"
          />
          <Text
            color={theme.colors.green300}
            weight={theme.fonts.weight.bold}
            size={theme.fonts.size.h2}
          >
            Cuidado del Cabello
          </Text>
        </Card>
        <Card p="20px" align="center">
          <ImgWrapper
            background={skinNutrition}
            width="200px"
            height="200px"
            radius="50%"
          />
          <Text
            color={theme.colors.green300}
            weight={theme.fonts.weight.bold}
            size={theme.fonts.size.h2}
          >
            Cuidado de la Piel
          </Text>
        </Card>
        <Card p="20px" align="center">
          <ImgWrapper
            background={home}
            width="200px"
            height="200px"
            radius="50%"
          />
          <Text
            color={theme.colors.green300}
            weight={theme.fonts.weight.bold}
            size={theme.fonts.size.h2}
          >
            Cuidado del Hogar
          </Text>
        </Card>
        <Row
          m="10px 0 40px 0"
          p="50px"
          width="100%"
          gap="20px"
          style={{ overflowX: "auto" }}
        >
          <Card p="20px" style={{ "min-width": "200px" }}>
            <Text
              color={theme.colors.green}
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h5}
              mb="10px"
            >
              ¿Qué nos hace especiales?
            </Text>
            <Text>
              En Verde Vital, creemos en el poder de la naturaleza para brindar
              soluciones para una vida más saludable y equilibrada. Nuestros
              productos están cuidadosamente seleccionados para ofrecerte lo
              mejor de la naturaleza.
            </Text>
          </Card>
          <Card p="20px" style={{ "min-width": "200px" }}>
            <Text
              color={theme.colors.green}
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h5}
              mb="10px"
            >
              Estamos construyendo algo emocionante ...
            </Text>
            <Text>
              Actualmente, estamos trabajando arduamente en nuestra nueva página
              web para brindarte una experiencia de compra en línea única y
              conveniente. Queremos que encuentres todo lo que necesitas para tu
              bienestar en un solo lugar.
            </Text>
          </Card>
        </Row>
        <Col gap="10px">
          <Text
            color={theme.colors.green}
            weight={theme.fonts.weight.bold}
            size={theme.fonts.size.h2}
          >
            Mientras tanto,
          </Text>
          <Text
            color={theme.colors.green500}
            weight={theme.fonts.weight.bold}
            size={theme.fonts.size.h2}
          >
            ¿quieres más información?
          </Text>
        </Col>
        <Row width="100%" justify="center" align="center">
          <Button
            onClick={() => openWhatsappChat("+573164231214")}
            width="500px"
            background={theme.colors.green300}
          >
            <Row gap="10px" justify="center" align="center">
              <WhatsAppOutlined
                style={{
                  color: theme.colors.white,
                }}
              />
              <Text
                color={theme.colors.white}
                weight={theme.fonts.weight.regular}
                size={theme.fonts.size.h5}
              >
                Whatsapp
              </Text>
            </Row>
          </Button>
        </Row>
      </Col>
    </Container>
  ) : (
    <Container background={backgroundHome}>
      <Row width="100hw">
        <Col
          m="0 50px 0 0"
          p="0 0 0 50px"
          height="450px"
          radius="0 0 150px 0"
          justify="center"
          style={{
            flex: 1,
            background: `linear-gradient(to bottom, ${theme.colors.green200},${theme.colors.green50} )`,
          }}
        >
          <Row align="center">
            <img alt="logo" src={logo} width="100px" height="100px" />
            <Col>
              <Text
                color={theme.colors.green500}
                weight={theme.fonts.weight.bold}
                size={theme.fonts.size.h1}
              >
                Verde Vital
              </Text>
              <Text
                color={theme.colors.green}
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.h5}
              >
                Tú mejor aliado
              </Text>
            </Col>
          </Row>
          <Row gap="10px">
            <Text
              color={theme.colors.green300}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h1}
            >
              PRODUCTOS
            </Text>
            <Text
              color={theme.colors.green500}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h1}
            >
              NATURALES
            </Text>
          </Row>
          <Row gap="10px">
            <Text
              color={theme.colors.green300}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h1}
            >
              PARA EL
            </Text>
            <Text
              color={theme.colors.green500}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h1}
            >
              DÍA A DÍA
            </Text>
          </Row>

          <Button
            size={theme.fonts.size.default}
            weight={theme.fonts.weight.semibold}
            onClick={() => openWhatsappChat("+573164231214")}
            width="280px"
            m="10px 0 0 0"
            background={theme.colors.green300}
          >
            <WhatsAppOutlined style={{ color: theme.colors.white }} />
            Hablar por Whatsapp
          </Button>
        </Col>
        <Col m="50px 50px 0 0" style={{ flex: 1 }}>
          <Col>
            <Text
              color={theme.colors.green}
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h2}
            >
              ¡Bienvenido a Verde Vital!
            </Text>
            <Text size={theme.fonts.size.h4}>
              ¡Estamos emocionados de tenerte aquí! En Verde Vital nos dedicamos
              a ofrecerte productos naturales de alta calidad para mejorar tu
              bienestar y estilo de vida.
            </Text>
          </Col>
          <Row width="100%" justify="center" m="10px 0 20px 0">
            <ImgWrapper
              background={naturalProducts}
              width="400px"
              height="250px"
              radius="0 50px 0 50px"
            />
          </Row>
        </Col>
      </Row>
      <Col>
        <Row justify="right">
          <Div
            p="10px 50px 0 0"
            width="400px"
            height="50px"
            justify="right"
            style={{
              "border-radius": "50px 50px 0 0",
              background: theme.colors.green200,
            }}
          >
            <Text
              color={theme.colors.green500}
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h2}
            >
              Encuentra...
            </Text>
          </Div>
        </Row>

        <Col
          p="50px"
          align="center"
          radius="150px 0 0 0"
          style={{
            background: `linear-gradient(to bottom, ${theme.colors.green200},${theme.colors.green50} )`,
          }}
        >
          <Row m="0 0 40px 0" gap="20px">
            <Card p="10px 20px 10px 20px" align="center">
              <ImgWrapper
                background={gyh}
                width="200px"
                height="200px"
                radius="50%"
              />
              <Text
                color={theme.colors.green300}
                weight={theme.fonts.weight.bold}
                size={theme.fonts.size.h2}
              >
                Cuidado Personal
              </Text>
            </Card>
            <Card p="20px" align="center">
              <ImgWrapper
                background={satinique}
                width="200px"
                height="200px"
                radius="50%"
              />
              <Text
                color={theme.colors.green300}
                weight={theme.fonts.weight.bold}
                size={theme.fonts.size.h2}
              >
                Cuidado del Cabello
              </Text>
            </Card>
            <Card p="20px" align="center">
              <ImgWrapper
                background={skinNutrition}
                width="200px"
                height="200px"
                radius="50%"
              />
              <Text
                color={theme.colors.green300}
                weight={theme.fonts.weight.bold}
                size={theme.fonts.size.h2}
              >
                Cuidado de la Piel
              </Text>
            </Card>
            <Card p="20px" align="center">
              <ImgWrapper
                background={home}
                width="200px"
                height="200px"
                radius="50%"
              />
              <Text
                color={theme.colors.green300}
                weight={theme.fonts.weight.bold}
                size={theme.fonts.size.h2}
              >
                Cuidado del Hogar
              </Text>
            </Card>
          </Row>
          <Row m="0 0 40px 0" gap="20px">
            <Card p="20px">
              <Text
                color={theme.colors.green}
                weight={theme.fonts.weight.bold}
                size={theme.fonts.size.h2}
              >
                ¿Qué nos hace especiales?
              </Text>
              <Text>
                En Verde Vital, creemos en el poder de la naturaleza para
                brindar soluciones para una vida más saludable y equilibrada.
                Nuestros productos están cuidadosamente seleccionados para
                ofrecerte lo mejor de la naturaleza.
              </Text>
            </Card>
            <Card p="20px">
              <Text
                color={theme.colors.green}
                weight={theme.fonts.weight.bold}
                size={theme.fonts.size.h2}
              >
                Estamos construyendo algo emocionante...
              </Text>
              <Text>
                Actualmente, estamos trabajando arduamente en nuestra nueva
                página web para brindarte una experiencia de compra en línea
                única y conveniente. Queremos que encuentres todo lo que
                necesitas para tu bienestar en un solo lugar.
              </Text>
            </Card>
          </Row>
          <Row gap="10px">
            <Text
              color={theme.colors.green}
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h2}
            >
              Mientras tanto,
            </Text>
            <Text
              color={theme.colors.green500}
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h2}
            >
              ¿quieres más información?
            </Text>
          </Row>

          <Button
            size={theme.fonts.size.default}
            weight={theme.fonts.weight.semibold}
            onClick={() => openWhatsappChat("+573164231214")}
            width="280px"
            m="10px 0 0 0"
            background={theme.colors.green300}
          >
            <WhatsAppOutlined style={{ color: theme.colors.white }} />
            Hablar por Whatsapp
          </Button>
        </Col>
      </Col>
    </Container>
  );
};
