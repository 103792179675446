import React from "react";
import { CustomButton } from "./styles";

export const Button = ({
  children,
  width,
  background,
  color,
  border,
  onClick,
  weight,
  height,
  radius,
  ...rest
}) => {
  return (
    <CustomButton
      width={width}
      background={background}
      color={color}
      border={border}
      onClick={onClick}
      weight={weight}
      height={height}
      radius={radius}
      {...rest}
    >
      {children}
    </CustomButton>
  );
};
