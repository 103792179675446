import { createSlice } from "@reduxjs/toolkit";
import { axiosClient } from "../../services/axiosInstance";

export const slice = createSlice({
  name: "patients",
  initialState: {
    selectedPatient: {},
  },
  reducers: {
    setSelectedPatient: (state, action) => {
      return {
        ...state,
        selectedPatient: action.payload,
      };
    },
  },
});

export default slice.reducer;

export const { setSelectedPatient } = slice.actions;

export const setSelectedPatientInformation = (data) => async (dispatch) => {
  dispatch(setSelectedPatient(data));
};
