import React, { useEffect, useState, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Text,
  Div,
  Col,
  Card,
  Grid,
} from "../../styles/Common";
import { Button } from "../../components/Button";
import { theme } from "../../styles/theme";
import cleanerImg from "../../assets/images/cleaner.jpg";
import deodorantDemo from "../../assets/videos/cleanerDemo.mp4";
import { ImgBackgroundWrapper, ImgWrapper } from "./styles";
import {
  benefits,
  components,
  testimonials,
  getWhatsappLinks,
} from "./helpers";
import useFBApiConvertion from "../../core/hooks/useFBApiConvertion";
import { getUserIP } from "../../utilities/helpers";
import { CheckPasswordIcon, ChevronRightIcon } from "../../assets/icons";
import cleanerPrice from "../../assets/images/cleanerPrice.png";
import deodorant2 from "../../assets/images/2Deodorant.webp";

export const SkinNutritionCleaner = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: 768px)`,
  });

  const { search } = useLocation();
  const pageParam = new URLSearchParams(search).get("page");

  const productsDataRef = useRef(null);

  const fbApiConvertion = useFBApiConvertion();

  const fbConvertionEvent = async (event) => {
    const ip = await getUserIP();
    const viewContentData = {
      data: [
        {
          event_name: event,
          event_time: Math.floor(new Date().getTime() / 1000),
          action_source: "website",
          user_data: {
            client_ip_address: ip,
            client_user_agent: navigator.userAgent,
            country: [
              "c330ec504d82c24fcd10be978fa74b8a3185a8df719604a85443bb9ca279f5a2",
            ],
          },
        },
      ],
    };
    fbApiConvertion.mutate(viewContentData);
  };

  useEffect(() => {
    fbConvertionEvent("ViewContent");
  }, []);

  const getRandomUnitsSold = () => {
    return Math.floor(Math.random() * (45 - 35 + 1)) + 35;
  };
  const [unitsSold, setUnitsSold] = useState(getRandomUnitsSold());

  useEffect(() => {
    const interval = setInterval(() => {
      setUnitsSold((prevTotal) => {
        const decrement = Math.floor(Math.random() * (2 - 1 + 1)) + 1;
        return Math.max(prevTotal - decrement, 5);
      });
    }, Math.floor(Math.random() * (25000 - 5000 + 1)) + 5000);
  }, []);

  const goToProductPrice = () => {
    fbConvertionEvent("AddToCart");
    productsDataRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const openWhatsappToBuy = async (kit) => {
    fbConvertionEvent("InitiateCheckout");
    const whatsappLinks = getWhatsappLinks(pageParam);
    window.open(whatsappLinks[`kit${kit}`], "_blank");
  };

  return isMobile ? (
    <Div background={theme.colors.white}>
      <ImgBackgroundWrapper p="0 20px 0 20px">
        <Col align="center" width="100%">
          <Row m="50px 100px 0 100px">
            <Text
              color={theme.colors.black}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h2}
            >
              DESCUBRE EL SECRETO PARA UNA PIEL RADIANTE
            </Text>
          </Row>
          <Col m="20px 0 0 0" align="center" style={{ gap: "10px" }}>
            <ImgWrapper
              background={cleanerImg}
              width="300px"
              height="350px"
              radius="50px 0 50px 0"
              borderColor={theme.colors.green300}
              alt="Limpiador vegano"
            />
            <Row m="20px 0 0 0" width="100%" justify="center">
              <Text
                color={theme.colors.black}
                weight={theme.fonts.weight.extrabold}
                size={theme.fonts.size.h4}
              >
                LIBRE DE IMPUREZAS
              </Text>
            </Row>
            <Row m="0 0 0 0">
              <Div
                width="350px"
                height="350px"
                radius="50px"
                style={{
                  position: "relative",
                  overflow: "hidden",
                  border: "5px solid #63B844",
                }}
              >
                <video
                  width="100%"
                  height="100%"
                  style={{ objectFit: "cover" }}
                  controls
                  defaultPlaybackRate={1.25}
                >
                  <source src={deodorantDemo} type="video/mp4" />
                  Tu navegador no soporta el elemento de video.
                </video>
              </Div>
            </Row>
            <Row justify="center">
              <Text
                color={"#309f09"}
                weight={theme.fonts.weight.extrabold}
                size={theme.fonts.size.h4}
              >
                Y SIN EXCESO DE GRASA
              </Text>
            </Row>
          </Col>
          <Col justify="center" align="center" m="20px 0 0 0">
            <Text
              color={theme.colors.red}
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h4}
            >
              {unitsSold} unidades
            </Text>
            <Text
              color={theme.colors.black}
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h4}
            >
              disponibles
            </Text>
          </Col>
          <Row m="20px 0 0 0" width="100%" justify="center">
            <Button
              size={theme.fonts.size.h3}
              weight={theme.fonts.weight.bold}
              onClick={goToProductPrice}
              width="100%"
              height="80px"
              m="10px 0 20px 0"
              background={theme.colors.red}
              radius="50px"
              style={{ padding: "0 20px 0 20px" }}
              p="5px 20px"
              animate={true}
            >
              QUIERO EL MÍO
            </Button>
          </Row>
          <Row justify="center" align="center">
            <Text
              color={theme.colors.black}
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h4}
            >
              Entrega Segura
            </Text>
            <CheckPasswordIcon stroke={theme.colors.white} />
          </Row>
          <Row m="20px 0 0 0" width="100%" justify="center">
            <Card
              p="20px 10px"
              style={{ margin: "0 20px 0 20px" }}
              background={theme.colors.white}
            >
              <Text
                color={theme.colors.green300}
                weight={theme.fonts.weight.extrabold}
                size={theme.fonts.size.h4}
              >
                Fórmula innovadora <br></br> Alta calidad y extractos botánicos
              </Text>
              <Text
                color={theme.colors.black}
                weight={theme.fonts.weight.extrabold}
                size={theme.fonts.size.h4}
              >
                Aloe Vera y Shiso Perilla
              </Text>
              <Text
                color={theme.colors.black}
                weight={theme.fonts.weight.extrabold}
                size={theme.fonts.size.h4}
              >
                Limpieza PROFUNDA y SUAVE a la piel, sin exceso de grasa e
                impurezas.
              </Text>
            </Card>
          </Row>
          <Row m="20px" justify="center">
            <Card
              p="10px"
              width="100%"
              background={theme.colors.white}
              justify="center"
              align="center"
              style={{ margin: "20px 0 20px 0" }}
            >
              <Grid m="20px 0 10px 0">
                {benefits.map((benefit) => {
                  return (
                    <Card
                      p="20px 10px"
                      width="150px"
                      height="200px"
                      background={theme.colors.green300}
                      justify="center"
                      align="center"
                    >
                      {React.cloneElement(benefit.icon, {
                        style: {
                          fontSize: "40px",
                          color: theme.colors.white,
                        },
                      })}
                      <Text
                        color={theme.colors.white}
                        weight={theme.fonts.weight.bold}
                        size={theme.fonts.size.h5}
                        mt="10px"
                      >
                        {benefit.text}
                      </Text>
                    </Card>
                  );
                })}
              </Grid>
            </Card>
          </Row>

          <Card
            p="20px 20px"
            background={theme.colors.white}
            ref={productsDataRef}
          >
            <Col gap="20px" justify="space-around" align="center">
              <Col
                justify="center"
                align="center"
                style={{
                  gap: "15px",
                  borderColor: "#63B844",
                  borderWidth: "3px",
                  borderRadius: "20px",
                  borderStyle: "solid",
                  padding: "10px",
                }}
              >
                <Text
                  color={theme.colors.black}
                  weight={theme.fonts.weight.extrabold}
                  size={theme.fonts.size.h3}
                >
                  1 Limpiador Dermatológico:
                  <span style={{ textDecoration: "line-through" }}>
                    {" "}
                    $120.000
                  </span>{" "}
                  <br></br>
                  $96.000
                </Text>
                <Text
                  color={theme.colors.orange500}
                  weight={theme.fonts.weight.extrabold}
                  size={theme.fonts.size.h3}
                >
                  con descuento
                </Text>
                <ImgWrapper
                  background={cleanerPrice}
                  width="250px"
                  height="250px"
                  alt={"cleanerPrice"}
                  radius="30px"
                />
                <Text
                  color={theme.colors.orange500}
                  weight={theme.fonts.weight.extrabold}
                  size={theme.fonts.size.h3}
                >
                  por solo $16.000 por mes
                </Text>
                <Button
                  size={theme.fonts.size.h3}
                  weight={theme.fonts.weight.bold}
                  onClick={() => openWhatsappToBuy(1)}
                  width="100%"
                  height="80px"
                  m="10px"
                  background={theme.colors.red}
                  radius="50px"
                  style={{ padding: "0 20px 0 20px" }}
                >
                  Pedir
                </Button>
              </Col>
            </Col>
          </Card>

          <Card
            p="20px 20px"
            background={theme.colors.white}
            style={{ margin: "20px 0 0 0" }}
          >
            <Text
              color={theme.colors.green300}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h3}
              mb="20px"
            >
              Extractos botánicos que lo componen:
            </Text>
            {components.map((benefit) => {
              return (
                <Col
                  gap="10px"
                  align="center"
                  justify="space-around"
                  m="0 0 10px 0"
                >
                  <ImgWrapper
                    background={benefit.icon}
                    width="150px"
                    height="150px"
                    radius="50px 0 50px 0"
                    borderColor={theme.colors.green300}
                    alt="botanical-extract"
                  />
                  <Text
                    color={theme.colors.black}
                    weight={theme.fonts.weight.semibold}
                    size={theme.fonts.size.h5}
                    mt="10px"
                  >
                    {benefit.text}
                  </Text>
                </Col>
              );
            })}
          </Card>

          <Card
            background={theme.colors.white}
            align="center"
            style={{ margin: "20px 0 0 0" }}
          >
            <Text
              color={theme.colors.green300}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h4}
              mt="20px"
            >
              TESTIMONIOS
            </Text>

            <Row
              justify="space-between"
              width="100%"
              style={{ overflowX: "auto" }}
            >
              {testimonials.map((testimony) => {
                return (
                  <Card
                    p="20px"
                    background="transparent"
                    align="left"
                    width="300px"
                    style={{ margin: "55px 30px 10px 30px", flexShrink: 0 }}
                  >
                    <Text
                      color={theme.colors.black}
                      weight={theme.fonts.weight.bold}
                      size={theme.fonts.size.h5}
                    >
                      {testimony.name}
                    </Text>
                    <Text
                      color={theme.colors.black}
                      weight={theme.fonts.weight.medium}
                      size={theme.fonts.size.h5}
                    >
                      {testimony.testimony}
                    </Text>
                  </Card>
                );
              })}
            </Row>
          </Card>
          <Col m="20px" align="center" justify="center">
            <Text
              color={theme.colors.black}
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h2}
              width="100%"
            >
              Pide Aquí
            </Text>
            <Button
              size={theme.fonts.size.h4}
              weight={theme.fonts.weight.bold}
              onClick={goToProductPrice}
              width="100%"
              height="100%"
              m="20px"
              background={theme.colors.red}
              radius="50px"
              p="5px 20px"
            >
              APROVECHA LA PROMO
            </Button>
          </Col>
        </Col>
      </ImgBackgroundWrapper>
    </Div>
  ) : (
    <Div background={theme.colors.white}>
      <ImgBackgroundWrapper>
        <Col align="center" width="100%">
          <Row m="100px 300px 0 300px">
            <Text
              color={theme.colors.black}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h1}
              align="center"
            >
              DESCUBRE EL SECRETO PARA UNA PIEL RADIANTE
            </Text>
          </Row>
          <Row m="50px 0 0 0" align="center" gap="10px">
            <ImgWrapper
              background={cleanerImg}
              width="500px"
              height="550px"
              radius="50px 0 50px 0"
              borderColor={theme.colors.green300}
              alt="Desodorante antitranspirante natural vegano"
            />
            <Col m="0 0 0 5px" align="center" style={{ gap: "5px" }}>
              <Text
                color={theme.colors.black}
                weight={theme.fonts.weight.extrabold}
                size={theme.fonts.size.h3}
                mt="20px"
              >
                LIBRE DE IMPUREZAS
              </Text>
              <Div
                width="400px"
                height="420px"
                radius="50px"
                style={{
                  position: "relative",
                  overflow: "hidden",
                  border: "5px solid #63B844",
                }}
              >
                <video
                  width="100%"
                  height="100%"
                  style={{ objectFit: "cover" }}
                  controls
                >
                  <source src={deodorantDemo} type="video/mp4" />
                  Tu navegador no soporta el elemento de video.
                </video>
              </Div>
              <Text
                color={"#309f09"}
                weight={theme.fonts.weight.extrabold}
                size={theme.fonts.size.h3}
              >
                Y SIN EXCESO DE GRASA
              </Text>
            </Col>
          </Row>
          <Row
            justify="center"
            align="center"
            m="20px 0 0 0"
            style={{ gap: "15px" }}
          >
            <Text
              color={theme.colors.red}
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h2}
            >
              {unitsSold} unidades
            </Text>
            <Text
              color={theme.colors.black}
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h2}
            >
              disponibles
            </Text>
          </Row>
          <Row m="10px 0 0 0">
            <Button
              size={theme.fonts.size.h3}
              weight={theme.fonts.weight.bold}
              onClick={goToProductPrice}
              width="100%"
              height="80px"
              m="10px"
              background={theme.colors.red}
              radius="50px"
              style={{ padding: "0 20px 0 20px" }}
              animate={true}
            >
              QUIERO EL MÍO
            </Button>
          </Row>
          <Row justify="center" align="center" m="0 0 20px 0">
            <Text
              color={theme.colors.black}
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h2}
            >
              Entrega Segura
            </Text>
            <CheckPasswordIcon stroke={theme.colors.white} />
          </Row>
          <Card p="20px 0" background={theme.colors.white}>
            <Text
              color={theme.colors.green300}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h2}
            >
              Fórmula innovadora <br></br> Alta calidad y extractos botánicos
            </Text>
            <Text
              color={theme.colors.black}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h2}
            >
              Aloe Vera y Shiso Perilla
            </Text>
            <Text
              color={theme.colors.black}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h2}
            >
              Limpieza PROFUNDA y SUAVE a la piel, sin exceso de grasa e
              impurezas.
            </Text>
          </Card>
          <Card
            p="20px 20px"
            background={theme.colors.white}
            style={{ margin: "20px 0 40px 0" }}
          >
            <Row width="100%" justify="center" m="20px 0 10px 0" gap="20px">
              {benefits.map((benefit) => {
                return (
                  <Card
                    p="20px 10px"
                    background={theme.colors.green300}
                    justify="center"
                  >
                    {benefit.icon}
                    <Text
                      color={theme.colors.white}
                      weight={theme.fonts.weight.extrabold}
                      size={theme.fonts.size.h3}
                      mt="10px"
                    >
                      {benefit.text}
                    </Text>
                  </Card>
                );
              })}
            </Row>
          </Card>

          <Card
            p="20px 20px"
            background={theme.colors.white}
            ref={productsDataRef}
          >
            <Col
              justify="center"
              align="center"
              style={{
                gap: "15px",
                borderColor: "#63B844",
                borderWidth: "3px",
                borderRadius: "20px",
                borderStyle: "solid",
                padding: "10px",
              }}
            >
              <Text
                color={theme.colors.black}
                weight={theme.fonts.weight.extrabold}
                size={theme.fonts.size.h2}
              >
                1 Limpiador vegano:
                <span style={{ textDecoration: "line-through" }}>
                  {" "}
                  $120.000
                </span>{" "}
                <br></br>
                $96.000
              </Text>
              <Text
                color={theme.colors.orange500}
                weight={theme.fonts.weight.extrabold}
                size={theme.fonts.size.h2}
              >
                con descuento
              </Text>
              <ImgWrapper
                background={cleanerPrice}
                width="400px"
                height="400px"
                alt={"limpiador vegano"}
                radius="30px"
              />
              <Text
                color={theme.colors.orange500}
                weight={theme.fonts.weight.extrabold}
                size={theme.fonts.size.h2}
              >
                por solo $16.000 por mes
              </Text>
              <Button
                size={theme.fonts.size.h3}
                weight={theme.fonts.weight.bold}
                onClick={() => openWhatsappToBuy(1)}
                width="100%"
                height="80px"
                m="10px"
                background={theme.colors.red}
                radius="50px"
                style={{ padding: "0 20px 0 20px" }}
              >
                Pedir
              </Button>
            </Col>
          </Card>

          <Card
            p="20px 20px"
            background={theme.colors.white}
            style={{ margin: "20px 0 0 0" }}
          >
            <Text
              color={theme.colors.green300}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h2}
              mb="20px"
            >
              Extractos botánicos que lo componen:
            </Text>
            {components.map((benefit) => {
              return (
                <Row
                  gap="10px"
                  align="center"
                  justify="space-around"
                  m="0 0 20px 0"
                >
                  <ImgWrapper
                    background={benefit.icon}
                    width="150px"
                    height="150px"
                    radius="50px 0 50px 0"
                    borderColor={theme.colors.green300}
                    alt="botanical-extract"
                  />
                  <Text
                    color={theme.colors.black}
                    weight={theme.fonts.weight.semibold}
                    size={theme.fonts.size.h4}
                    mt="10px"
                    align="left"
                    style={{ width: "900px" }}
                  >
                    {benefit.text}
                  </Text>
                </Row>
              );
            })}
          </Card>

          <Card
            p="20px 20px"
            background={theme.colors.white}
            style={{ margin: "20px 0 0 0" }}
          >
            <Text
              color={theme.colors.green300}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h3}
            >
              TESTIMONIOS
            </Text>
            {testimonials.map((testimony) => {
              return (
                <Card
                  p="20px"
                  background="transparent"
                  align="left"
                  style={{ margin: "20px 0 10px 0" }}
                >
                  <Text
                    color={theme.colors.black}
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.h5}
                  >
                    {testimony.name}
                  </Text>
                  <Text
                    color={theme.colors.black}
                    weight={theme.fonts.weight.medium}
                    size={theme.fonts.size.h5}
                  >
                    {testimony.testimony}
                  </Text>
                </Card>
              );
            })}
          </Card>

          <Row m="20px 0 20px 0" align="center" justify="center">
            <Text
              color={theme.colors.black}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.high}
              width="100%"
            >
              Pide Aquí
            </Text>
            <Button
              size={theme.fonts.size.h3}
              weight={theme.fonts.weight.bold}
              onClick={goToProductPrice}
              width="100%"
              height="80px"
              m="10px"
              background={theme.colors.red}
              radius="50px"
            >
              APROVECHA LA PROMO
            </Button>
          </Row>
        </Col>
      </ImgBackgroundWrapper>
    </Div>
  );
};
