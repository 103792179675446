import {
  ClearOutlined,
  LikeOutlined,
  CheckCircleOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";
import { theme } from "../../styles/theme";
import extract1 from "../..//assets/images/extract1.jpg";
import extract2 from "../..//assets/images/extract2.jpg";
import extract3 from "../..//assets/images/extract3.jpg";
import extract4 from "../..//assets/images/extract4.jpg";

/* Informacion de los beneficios */
export const benefits = [
  {
    icon: (
      <ClearOutlined style={{ fontSize: "60px", color: theme.colors.white }} />
    ),
    text: "Limpia a profundidad los poros",
  },
  {
    icon: (
      <CheckCircleOutlined
        style={{ fontSize: "60px", color: theme.colors.white }}
      />
    ),
    text: "Libre de parabenos, aceites minerales y ftalatos",
  },
  {
    icon: (
      <LikeOutlined style={{ fontSize: "60px", color: theme.colors.white }} />
    ),
    text: "Dermatológico e hipoalergénico",
  },
  {
    icon: (
      <ReconciliationOutlined
        style={{ fontSize: "60px", color: theme.colors.white }}
      />
    ),
    text: "Vegano, Sin testeo en animales",
  },
];

/* Información de los productos */
const openWhatsappChat = (kit) => {
  switch (kit) {
    case 1:
      window.open(`https://wa.link/4ww5cc`, "_blank");
      break;
    case 2:
      window.open(`https://wa.link/j68256`, "_blank");
      break;
    case 3:
      window.open(`https://wa.link/6ixnya`, "_blank");
      break;

    default:
      window.open(`https://wa.link/7luy7e`, "_blank");
      break;
  }
};

export const testimonials = [
  {
    name: "Einer Bran.",
    testimony:
      "Desde que empecé a usar el jabón para piel grasa, mi rostro ha cambiado por completo, ya mi piel no se ve grasosa en el día, se ve y se siente limpia y me ayudo a controlar el acné ",
  },
  {
    name: "Alejandra Gómez: ",
    testimony:
      "Nunca había encontrado un jabón que controlara tan bien la grasa de mi piel como este y que me ayudara a combatir el acné  ¡Lo recomiendo al 100%!",
  },
  {
    name: "Maria Pineda",
    testimony:
      "Mis poros se han reducido notablemente desde que incorporé este jabón a mi rutina diaria. ¡Estoy encantada con los resultados!",
  },
  {
    name: "Mateo",
    testimony:
      "Me ha gustado mucho la sensación de frescura y suavidad que deja el jabón, controla la grasa súper bien sin dejarme la piel tirante y reseca.",
  },
];

export const components = [
  {
    icon: extract1,
    text: "Semilla de chía blanca: Ayuda a proteger la humectación y el ph natural de la piel ",
  },
  {
    icon: extract2,
    text: "Cereza Acerola, Margarita Australiana y zarzamora : Potentes componentes activos antioxidantes que refuerzan el sistema de defensa de la piel y ayudan a prevenir el envejecimiento prematuro. ",
  },
  {
    icon: extract3,
    text: "Aloe Vera y Shiso Perilla : Mezcla de dos extractos que ayudan a desinflamar la piel y al proceso de cicatización",
  },
  {
    icon: extract4,
    text: "Aminoacidos: Agentes suaves que ayudan a eliminar delicadamente las impurezas de la piel ",
  },
];
export const getWhatsappLinks = (page) => {
  switch (page) {
    case "natalNatural":
      return {
        kit1: "https://wa.link/wz979u",
      };
    case "mariaIsabel":
      return {
        kit1: "https://wa.link/6vg7ru",
      };
    case "colibri":
      return {
        kit1: "https://wa.link/9llcoj",
      };
    default:
      return {
        kit1: "https://wa.link/mrr18z",
      };
  }
};
