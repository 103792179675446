import React from "react";
import { CustomLink } from "./styles";

export const Link = ({
  children,
  color,
  backgroundhover,
  onClick,
  size,
  ...rest
}) => {
  return (
    <CustomLink
      type="link"
      backgroundhover={backgroundhover}
      color={color}
      onClick={onClick}
      size={size}
      {...rest}
    >
      {children}
    </CustomLink>
  );
};
