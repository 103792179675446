import decode from "jwt-decode";
import axios from "axios";
import useFBApiConvertion from "../core/hooks/useFBApiConvertion";

export const setLocalValue = (name, value) => {
  localStorage.setItem(name, value);
};

export const getToken = () => {
  const user = localStorage.getItem("user");

  return user;
};
export const getUserInfo = () => {
  const user = localStorage.getItem("user")
    ? decode(localStorage.getItem("user"))
    : {
        image: "",
        lastname: "",
        name: "",
        rol: "",
        id: null,
      };
  return user;
};

export const getUserIP = async () => {
  const response = await axios.get("https://api64.ipify.org?format=json");
  return response.data.ip;
};
