import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "common",
  initialState: {
    firstTime: false,
    clinicsList: [],
    codesList: [],
  },
  reducers: {
    setFirstTime: (state, action) => {
      return {
        ...state,
        firstTime: action.payload,
      };
    },
    setClinicsList: (state, action) => {
      return {
        ...state,
        clinicsList: action.payload,
      };
    },
    setCodesList: (state, action) => {
      return {
        ...state,
        codesList: action.payload,
      };
    },
  },
});

export default slice.reducer;

export const { setFirstTime, setClinicsList, setCodesList } = slice.actions;

export const setClinicsListInformation = (data) => async (dispatch) => {
  dispatch(setClinicsList(data));
};

export const setCodesListInformation = (data) => async (dispatch) => {
  dispatch(setCodesList(data));
};
