/* Informacion de los beneficios */
export const benefits = [
  {
    text: "- Control de Peso",
  },
  {
    text: "- Alivio del Estreñimiento ",
  },
  {
    text: "- Reducir Hígado Graso",
  },
  {
    text: "- Mejora Digestiva",
  },
];

/* Información de los productos */
export const openWhatsappChatKits = (kit) => {
  switch (kit) {
    case 1:
      window.open(`https://wa.link/bvo4ul`, "_blank");
      break;
    case 2:
      window.open(`https://wa.link/3s5ylu`, "_blank");
      break;
    case 3:
      window.open(`https://wa.link/9grsvr`, "_blank");
      break;

    default:
      window.open(`https://wa.link/f3f1io`, "_blank");
      break;
  }
};

export const testimonials = [
  {
    name: "Carolina",
    testimony:
      "Lecitina E ha cambiado mi vida. He perdido esos kilos no deseados y mi digestión nunca ha sido mejor. ¡Gracias por este increíble producto!",
  },
  {
    name: "Juan Camilo",
    testimony:
      "No solo he reducido mi hígado graso, sino que también he notado una mejora significativa en mi energía. ¡Lo recomendaría a todos",
  },
  {
    name: "Ana.",
    testimony:
      "Empecé a tomar la lecitina. Después de unas semanas, noté que mi digestión mejoró. ¡Es sorprendente cómo algo tan simple puede hacer una gran diferencia!",
  },
  {
    name: "Lina.",
    testimony:
      "Estoy impresionada por cómo ha ayudado a mi cuerpo a procesar mejor las grasas. Me siento más ligera y con más vitalidad. ¡Definitivamente la recomienda!",
  },
];
