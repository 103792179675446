import { createSlice } from "@reduxjs/toolkit";
import { axiosClient } from "../../services/axiosInstance";

export const slice = createSlice({
  name: "users",
  initialState: {
    selectedUser: {},
  },
  reducers: {
    setSelectedUser: (state, action) => {
      return {
        ...state,
        selectedUser: action.payload,
      };
    },
  },
});

export default slice.reducer;

export const { setSelectedUser } = slice.actions;

export const setSelectedUserInformation = (data) => async (dispatch) => {
  dispatch(setSelectedUser(data));
};
