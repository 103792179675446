import { Outlet } from "react-router-dom";
import "../styles/App.css";

const App = () => {
  return (
    <section id="app" className="App">
      <main className="main">
        <Outlet />
      </main>
    </section>
  );
};

export { App };
