import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Text,
  Div,
  Col,
  Card,
  Grid,
} from "../../styles/Common";
import { Button } from "../../components/Button";
import { theme } from "../../styles/theme";
import glisterCompleteKit from "../../assets/images/glisterCompleteKit.png";
import glister from "../../assets/images/glister-toothPaste.jpg";
import glisterSaving from "../../assets/images/glister-saving.png";
import glister2 from "../../assets/images/glister-2.png";
import glister3 from "../../assets/images/glister-3.png";
import glisterVideo1 from "../../assets/videos/glisterBenefits.mp4";
import glisterVideo2 from "../../assets/videos/glisterSaving.mp4";
import glisterVideo3 from "../../assets/videos/glisterSteps.mp4";
import { ImgBackgroundWrapper, ImgWrapper } from "./styles";
import { benefits, productsData, testimonials } from "./helpers";
import useFBApiConvertion from "../../core/hooks/useFBApiConvertion";
import { getUserIP } from "../../utilities/helpers";
import { StarFilled } from "@ant-design/icons";
import { getWhatsappLinks } from "./helpers";
import { CheckPasswordIcon } from "../../assets/icons";

export const Glister = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: 768px)`,
  });

  const { search } = useLocation();
  const pageParam = new URLSearchParams(search).get("page");

  const fbApiConvertion = useFBApiConvertion();

  const fbConvertionEvent = async (event) => {
    const ip = await getUserIP();
    const viewContentData = {
      data: [
        {
          event_name: event,
          event_time: Math.floor(new Date().getTime() / 1000),
          action_source: "website",
          user_data: {
            client_ip_address: ip,
            client_user_agent: navigator.userAgent,
            country: [
              "c330ec504d82c24fcd10be978fa74b8a3185a8df719604a85443bb9ca279f5a2",
            ],
          },
        },
      ],
    };
    fbApiConvertion.mutate(viewContentData);
  };

  useEffect(() => {
    fbConvertionEvent("ViewContent");
  }, []);

  const openWhatsappToBuy = async (kit) => {
    fbConvertionEvent("InitiateCheckout");
    const whatsappLinks = getWhatsappLinks(pageParam);
    window.open(whatsappLinks[`kit${kit}`], "_blank");
  };

  const getRandomUnitsSold = () => {
    return Math.floor(Math.random() * (12 - 4 + 1)) + 4;
  };
  const [unitsSold, setUnitsSold] = useState(getRandomUnitsSold());

  useEffect(() => {
    const interval = setInterval(() => {
      setUnitsSold((prevTotal) => prevTotal + 1);
    }, Math.floor(Math.random() * (20000 - 10000 + 1)) + 10000);

    return () => clearInterval(interval);
  }, []);

  return isMobile ? (
    <Div background={theme.colors.blue100}>
      <ImgBackgroundWrapper p="0 20px 0 20px">
        <Col align="center" width="100%">
          <Row m="50px 100px 0 100px">
            <Text
              color={theme.colors.springGreen}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h2}
            >
              ELIMINA SENSIBILIDAD
            </Text>
          </Row>
          <Row
            m="10px 100px 0 100px"
            p="20px"
            style={{ backgroundColor: theme.colors.green300 }}
          >
            <Text
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h2}
              color={theme.colors.white}
            >
              Brilla tu sonrisa
            </Text>
          </Row>
          <Row m="20px 0 0 0" align="center" justify="center" gap="40px">
            <ImgWrapper
              background={glister}
              width="250px"
              height="250px"
              radius="50px 0 50px 0"
              borderColor={theme.colors.white}
              alt="crema dental anti-sensibilidad"
            />
          </Row>
          <Row p="20px 0 0 0">
            <Text
              color={theme.colors.black}
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h4}
            >
              <span style={{ color: theme.colors.red }}>Rinde por 5</span>{" "}
              cremas dentales tradicionales
            </Text>
          </Row>
          <ImgWrapper
            background={glisterSaving}
            width="250px"
            height="120px"
            radius="20px"
            borderColor={theme.colors.white}
            alt="ahorro glister"
          />

          <Row m="20px 0 10px 0" align="center" justify="center" gap="40px">
            <Col
              align="left"
              p="10px 20px"
              radius="20px"
              style={{ gap: "5px", background: theme.colors.white }}
            >
              <Text
                color={theme.colors.green300}
                weight={theme.fonts.weight.extrabold}
                size={theme.fonts.size.h3}
              >
                Beneficios:
              </Text>
              {benefits.map((benefit) => {
                return (
                  <Text
                    color={theme.colors.black}
                    weight={theme.fonts.weight.semibold}
                    size={theme.fonts.size.h4}
                  >
                    {benefit.text}
                  </Text>
                );
              })}

              <Text
                color={theme.colors.springGreen}
                weight={theme.fonts.weight.extrabold}
                size={theme.fonts.size.h3}
              >
                Lo mejor para tí
              </Text>
            </Col>
          </Row>

          <Row justify="center">
            <Text
              color={theme.colors.black}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h2}
            >
              por solo: $27.700
            </Text>
          </Row>
          <Row justify="center" align="center">
            <Text
              color={theme.colors.black}
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h4}
            >
              Entrega Segura
            </Text>
            <CheckPasswordIcon stroke={theme.colors.green300} />
          </Row>
          <Row justify="center">
            <Button
              size={theme.fonts.size.h4}
              weight={theme.fonts.weight.bold}
              onClick={() => openWhatsappToBuy(0)}
              width="100%"
              height="80px"
              m="10px"
              background={theme.colors.red}
              radius="50px"
              style={{ padding: "0 20px 0 20px" }}
              animate={true}
            >
              QUIERO PEDIR
            </Button>
          </Row>

          <Row
            justify="center"
            align="center"
            m="20px 0 0 0"
            p="10px 20px"
            style={{ background: theme.colors.white, borderRadius: "20px" }}
          >
            <Text
              color={theme.colors.green300}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h3}
              mt="10px"
            >
              ¿Quieres comprar con descuento?
            </Text>
          </Row>
          <Card p="20px 20px" m="30px 0" background={theme.colors.white}>
            <Col gap="20px" justify="space-around">
              <Col align="center">
                <Text
                  color={theme.colors.black}
                  weight={theme.fonts.weight.extrabold}
                  size={theme.fonts.size.h3}
                  mt="10px"
                >
                  2 Unidades
                </Text>
                <ImgWrapper
                  background={glister2}
                  width="200px"
                  height="250px"
                  radius="50px"
                  alt="ahorro glister"
                />
                <Col justify="center" style={{ gap: "15px" }}>
                  <Text
                    color={theme.colors.black}
                    weight={theme.fonts.weight.extrabold}
                    size={theme.fonts.size.h4}
                    mt="10px"
                  >
                    Ahora: $55.400
                  </Text>
                </Col>
                <Button
                  size={theme.fonts.size.h4}
                  weight={theme.fonts.weight.bold}
                  onClick={() => openWhatsappToBuy("01")}
                  width="100%"
                  height="80px"
                  m="10px"
                  background={theme.colors.red}
                  radius="50px"
                  style={{ padding: "0 20px 0 20px" }}
                  animate={true}
                >
                  Pedir 2 unidades
                </Button>
              </Col>
              <Col align="center">
                <Text
                  color={theme.colors.black}
                  weight={theme.fonts.weight.extrabold}
                  size={theme.fonts.size.h4}
                  mt="10px"
                >
                  3 Unidades
                </Text>
                <ImgWrapper
                  background={glister3}
                  width="200px"
                  height="250px"
                  radius="50px"
                  alt="ahorro glister"
                />
                <Col justify="center" style={{ gap: "15px" }}>
                  <Text
                    color={theme.colors.red}
                    weight={theme.fonts.weight.extrabold}
                    size={theme.fonts.size.h3}
                    mt="10px"
                    style={{ textDecoration: "line-through" }}
                  >
                    Precio normal: $83.100
                  </Text>
                  <Text
                    color={theme.colors.black}
                    weight={theme.fonts.weight.extrabold}
                    size={theme.fonts.size.h2}
                    mt="10px"
                  >
                    Ahora: $74.800
                  </Text>
                </Col>
                <Button
                  size={theme.fonts.size.h4}
                  weight={theme.fonts.weight.bold}
                  onClick={() => openWhatsappToBuy("02")}
                  width="100%"
                  height="80px"
                  m="10px"
                  background={theme.colors.red}
                  radius="50px"
                  style={{ padding: "0 20px 0 20px" }}
                  animate={true}
                >
                  Pedir 3 unidades
                </Button>
              </Col>
            </Col>
          </Card>

          <Row
            justify="center"
            align="center"
            m="20px 0 0 0"
            p="10px 20px"
            style={{ background: theme.colors.white, borderRadius: "20px" }}
          >
            <Text
              color={theme.colors.green300}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h3}
              mt="10px"
            >
              ¿Quieres un cuidado completo?
            </Text>
          </Row>
          <Row p="20px">
            <Text
              color={theme.colors.red}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h4}
            >
              Promoción hasta agotar existencias
            </Text>
          </Row>
          <Row justify="center">
            <Text
              color={theme.colors.black}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h4}
            >
              Domicilio{" "}
              <span style={{ color: theme.colors.springGreen }}>GRATIS</span>{" "}
              solo por hoy
            </Text>
          </Row>
          <Row justify="center">
            <Button
              size={theme.fonts.size.h4}
              weight={theme.fonts.weight.bold}
              onClick={() => openWhatsappToBuy(1)}
              width="100%"
              height="80px"
              m="10px"
              background={theme.colors.red}
              radius="50px"
              style={{ padding: "0 20px 0 20px" }}
              animate={true}
            >
              QUIERO EL KIT
            </Button>
          </Row>

          <Row justify="center" style={{ gap: "15px" }}>
            <Text
              color={theme.colors.red}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h3}
              mt="10px"
              style={{ textDecoration: "line-through" }}
            >
              Antes: $149.900
            </Text>
            <Text
              color={theme.colors.black}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h3}
              mt="10px"
            >
              Ahora: $96.400
            </Text>
          </Row>
          <Row m="10px 0 0 0">
            <Text
              weight={theme.fonts.weight.semibold}
              size={theme.fonts.size.h5}
            >
              <span style={{ fontWeight: theme.fonts.weight.extrabold }}>
                Incluye:
              </span>{" "}
              Crema dental natural dientes sensibles, Enjuague bucal conentrado
              y cepillo de dientes.<br></br>Promoción por tiempo limitado
            </Text>
          </Row>
          <Row justify="center" align="center" style={{ gap: "15px" }}>
            <Text
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h2}
              color={theme.colors.springGreen}
              mt="20px"
            >
              {unitsSold} Unidades
            </Text>
          </Row>
          <Text
            color={theme.colors.black}
            weight={theme.fonts.weight.bold}
            size={theme.fonts.size.h3}
          >
            vendidas la ultima hora
          </Text>

          <Card p="20px 20px" background={theme.colors.white}>
            <Col gap="20px" justify="space-around" width="100%">
              <Div
                width="300px"
                height="350px"
                radius="25px"
                style={{
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <video
                  width="100%"
                  height="100%"
                  style={{ objectFit: "cover" }}
                  controls
                >
                  <source src={glisterVideo1} type="video/mp4" />
                  Tu navegador no soporta el elemento de video.
                </video>
              </Div>
              <Div
                width="300px"
                height="270px"
                radius="50px"
                style={{
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <video
                  width="100%"
                  height="100%"
                  style={{ objectFit: "cover" }}
                  controls
                >
                  <source src={glisterVideo2} type="video/mp4" />
                  Tu navegador no soporta el elemento de video.
                </video>
              </Div>
            </Col>
          </Card>
          <Row m="30px 0 10px 0" justify="center">
            <Button
              size={theme.fonts.size.h4}
              weight={theme.fonts.weight.bold}
              onClick={() => openWhatsappToBuy(1)}
              width="100%"
              height="80px"
              m="10px"
              background={theme.colors.red}
              radius="50px"
              style={{ padding: "0 20px 0 20px" }}
            >
              KIT PROMO
            </Button>
          </Row>
          <Row>
            <Text weight={theme.fonts.weight.bold} size={theme.fonts.size.h4}>
              Recibe hoy un E-book de cuidado bucal de REGALO
            </Text>
          </Row>

          <Card
            p="20px 20px"
            background={theme.colors.white}
            style={{ margin: "20px 0 0 0" }}
          >
            <Row align="center">
              <Text
                color={theme.colors.springGreen}
                weight={theme.fonts.weight.extrabold}
                size={theme.fonts.size.h4}
                mr="10px"
              >
                RESEÑAS
              </Text>
              <StarFilled style={{ color: theme.colors.yellow }} />
              <StarFilled style={{ color: theme.colors.yellow }} />
              <StarFilled style={{ color: theme.colors.yellow }} />
              <StarFilled style={{ color: theme.colors.yellow }} />
              <StarFilled style={{ color: theme.colors.yellow }} />
            </Row>
            {testimonials.map((testimony) => {
              return (
                <Card
                  p="20px"
                  background="transparent"
                  align="left"
                  style={{ margin: "20px 0 10px 0" }}
                >
                  <Text
                    color={theme.colors.black}
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.h5}
                  >
                    {testimony.name}
                  </Text>
                  <Text
                    color={theme.colors.black}
                    weight={theme.fonts.weight.medium}
                    size={theme.fonts.size.h5}
                  >
                    {testimony.testimony}
                  </Text>
                </Card>
              );
            })}
          </Card>
          <Row m="30px 0 20px 0">
            <Text weight={theme.fonts.weight.bold} size={theme.fonts.size.h4}>
              Otros complementos estrellas de nuestro Kit
            </Text>
          </Row>
          <ImgWrapper
            background={glisterCompleteKit}
            width="250px"
            height="250px"
            radius="50px 0 50px 0"
            borderColor={theme.colors.white}
            alt="kit dental anti-sensibilidad"
          />
          <Row m="20px 0 20px 0" align="center" justify="center">
            <Button
              weight={theme.fonts.weight.bold}
              onClick={() => openWhatsappToBuy(2)}
              width="100%"
              height="80px"
              m="10px"
              p="0 20px"
              background={theme.colors.red}
              radius="50px"
              animate={true}
            >
              KIT COMPLETO
            </Button>
          </Row>
        </Col>
      </ImgBackgroundWrapper>
    </Div>
  ) : (
    <Div background={theme.colors.blue100}>
      <ImgBackgroundWrapper>
        <Col align="center" width="100%">
          <Row m="100px 300px 0 300px">
            <Text
              color={theme.colors.springGreen}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h1}
            >
              ELIMINA SENSIBILIDAD
            </Text>
          </Row>
          <Row
            m="10px 300px 0 300px"
            p="20px"
            style={{ backgroundColor: theme.colors.green300 }}
          >
            <Text
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h1}
              color={theme.colors.white}
            >
              Brilla tu sonrisa
            </Text>
          </Row>
          <Row m="50px 0 0 0" align="center" justify="center" gap="40px">
            <ImgWrapper
              background={glister}
              width="500px"
              height="580px"
              radius="50px 0 50px 0"
              borderColor={theme.colors.white}
              alt="crema dental anti-sensibilidad"
            />
            <Col
              width="400px"
              align="left"
              p="10px 20px"
              radius="20px"
              style={{ gap: "5px", background: theme.colors.white }}
            >
              <Text
                color={theme.colors.green300}
                weight={theme.fonts.weight.extrabold}
                size={theme.fonts.size.h3}
                mt="20px"
              >
                Beneficios:
              </Text>
              {benefits.map((benefit) => {
                return (
                  <Text
                    color={theme.colors.black}
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.h4}
                    mt="20px"
                  >
                    {benefit.text}
                  </Text>
                );
              })}

              <Text
                color={theme.colors.springGreen}
                weight={theme.fonts.weight.extrabold}
                size={theme.fonts.size.h3}
                mt="20px"
              >
                Lo mejor para tí
              </Text>
            </Col>
          </Row>
          <Row p="20px 0 0 0">
            <Text
              color={theme.colors.black}
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h4}
            >
              <span style={{ color: theme.colors.red }}>Rinde por 5</span>{" "}
              cremas dentales tradicionales
            </Text>
          </Row>
          <ImgWrapper
            background={glisterSaving}
            width="600px"
            height="250px"
            radius="50px"
            borderColor={theme.colors.white}
            alt="ahorro glister"
          />
          <Row justify="center">
            <Text
              color={theme.colors.black}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h2}
            >
              por solo: $27.700
            </Text>
          </Row>
          <Row justify="center" align="center">
            <Text
              color={theme.colors.black}
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h4}
            >
              Entrega Segura
            </Text>
            <CheckPasswordIcon stroke={theme.colors.green300} />
          </Row>
          <Row justify="center">
            <Button
              size={theme.fonts.size.h4}
              weight={theme.fonts.weight.bold}
              onClick={() => openWhatsappToBuy(0)}
              width="100%"
              height="80px"
              m="10px"
              background={theme.colors.red}
              radius="50px"
              style={{ padding: "0 20px 0 20px" }}
              animate={true}
            >
              QUIERO PEDIR
            </Button>
          </Row>
          <Row justify="center" align="center" style={{ gap: "15px" }}>
            <Text
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h1}
              color={theme.colors.springGreen}
              mt="10px"
            >
              {unitsSold} Unidades
            </Text>
            <Text
              color={theme.colors.black}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h1}
              mt="10px"
            >
              vendidas la ultima hora
            </Text>
          </Row>
          <Row
            justify="center"
            align="center"
            m="20px 0 0 0"
            p="10px 20px"
            style={{ background: theme.colors.white, borderRadius: "20px" }}
          >
            <Text
              color={theme.colors.green300}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h1}
              mt="10px"
            >
              ¿Quieres comprar con descuento?
            </Text>
          </Row>
          <Card p="20px 20px" m="30px 0" background={theme.colors.white}>
            <Row gap="20px" justify="space-around">
              <Col align="center">
                <Text
                  color={theme.colors.black}
                  weight={theme.fonts.weight.extrabold}
                  size={theme.fonts.size.h2}
                  mt="10px"
                >
                  2 Unidades
                </Text>
                <ImgWrapper
                  background={glister2}
                  width="200px"
                  height="250px"
                  radius="50px"
                  alt="ahorro glister"
                />
                <Row justify="center" style={{ gap: "15px" }}>
                  <Text
                    color={theme.colors.black}
                    weight={theme.fonts.weight.extrabold}
                    size={theme.fonts.size.h3}
                    mt="10px"
                  >
                    Ahora: $55.400
                  </Text>
                </Row>
                <Button
                  size={theme.fonts.size.h3}
                  weight={theme.fonts.weight.bold}
                  onClick={() => openWhatsappToBuy("01")}
                  width="100%"
                  height="80px"
                  m="10px"
                  background={theme.colors.red}
                  radius="50px"
                  style={{ padding: "0 20px 0 20px" }}
                  animate={true}
                >
                  Pedir 2 unidades
                </Button>
              </Col>
              <Col align="center">
                <Text
                  color={theme.colors.black}
                  weight={theme.fonts.weight.extrabold}
                  size={theme.fonts.size.h3}
                  mt="10px"
                >
                  3 Unidades
                </Text>
                <ImgWrapper
                  background={glister3}
                  width="200px"
                  height="250px"
                  radius="50px"
                  alt="ahorro glister"
                />
                <Row justify="center" style={{ gap: "15px" }}>
                  <Text
                    color={theme.colors.red}
                    weight={theme.fonts.weight.extrabold}
                    size={theme.fonts.size.h3}
                    mt="10px"
                    style={{ textDecoration: "line-through" }}
                  >
                    Precio normal: $83.100
                  </Text>
                  <Text
                    color={theme.colors.black}
                    weight={theme.fonts.weight.extrabold}
                    size={theme.fonts.size.h2}
                    mt="10px"
                  >
                    Ahora: $74.800
                  </Text>
                </Row>
                <Button
                  size={theme.fonts.size.h3}
                  weight={theme.fonts.weight.bold}
                  onClick={() => openWhatsappToBuy("02")}
                  width="100%"
                  height="80px"
                  m="10px"
                  background={theme.colors.red}
                  radius="50px"
                  style={{ padding: "0 20px 0 20px" }}
                  animate={true}
                >
                  Pedir 3 unidades
                </Button>
              </Col>
            </Row>
          </Card>
          <Row
            justify="center"
            align="center"
            m="20px 0 0 0"
            p="10px 20px"
            style={{ background: theme.colors.white, borderRadius: "20px" }}
          >
            <Text
              color={theme.colors.green300}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h1}
              mt="10px"
            >
              ¿Quieres un cuidado completo?
            </Text>
          </Row>

          <Row justify="center" style={{ gap: "15px" }}>
            <Text
              color={theme.colors.red}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h2}
              mt="10px"
              style={{ textDecoration: "line-through" }}
            >
              Antes: $149.900
            </Text>
            <Text
              color={theme.colors.black}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h2}
              mt="10px"
            >
              Ahora: $96.400
            </Text>
          </Row>
          <Row>
            <Text
              color={theme.colors.red}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h4}
            >
              Promoción hasta agotar existencias
            </Text>
          </Row>
          <Row>
            <Text weight={theme.fonts.weight.medium} size={theme.fonts.size.h5}>
              <span style={{ fontWeight: theme.fonts.weight.extrabold }}>
                Incluye:
              </span>{" "}
              Crema dental natural dientes sensibles, Enjuague bucal conentrado
              y cepillo de dientes.<br></br>Promoción por tiempo limitado
            </Text>
          </Row>

          <Row>
            <Button
              size={theme.fonts.size.h3}
              weight={theme.fonts.weight.bold}
              onClick={() => openWhatsappToBuy(1)}
              width="100%"
              height="80px"
              m="10px"
              background={theme.colors.red}
              radius="50px"
              style={{ padding: "0 20px 0 20px" }}
              animate={true}
            >
              QUIERO EL KIT
            </Button>
          </Row>
          <Row justify="center" m="10px 0 20px 0">
            <Text
              color={theme.colors.black}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h2}
            >
              Domicilio{" "}
              <span style={{ color: theme.colors.springGreen }}>GRATIS</span>{" "}
              solo por hoy
            </Text>
          </Row>
          <Card p="20px 20px" background={theme.colors.white}>
            <Row gap="20px" justify="space-around">
              <Div
                width="300px"
                height="300px"
                radius="50px"
                style={{
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <video
                  width="100%"
                  height="100%"
                  style={{ objectFit: "cover" }}
                  controls
                >
                  <source src={glisterVideo1} type="video/mp4" />
                  Tu navegador no soporta el elemento de video.
                </video>
              </Div>
              <Div
                width="380px"
                height="300px"
                radius="50px"
                style={{
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <video
                  width="100%"
                  height="100%"
                  style={{ objectFit: "cover" }}
                  controls
                >
                  <source src={glisterVideo2} type="video/mp4" />
                  Tu navegador no soporta el elemento de video.
                </video>
              </Div>
              <Div
                width="400px"
                height="300px"
                radius="50px"
                style={{
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <video
                  width="100%"
                  height="100%"
                  style={{ objectFit: "cover" }}
                  controls
                >
                  <source src={glisterVideo3} type="video/mp4" />
                  Tu navegador no soporta el elemento de video.
                </video>
              </Div>
            </Row>
          </Card>
          <Row m="30px 0 10px 0">
            <Button
              size={theme.fonts.size.h3}
              weight={theme.fonts.weight.bold}
              onClick={() => openWhatsappToBuy(1)}
              width="100%"
              height="80px"
              m="10px"
              background={theme.colors.red}
              radius="50px"
              style={{ padding: "0 20px 0 20px" }}
            >
              KIT PROMO
            </Button>
          </Row>
          <Row>
            <Text weight={theme.fonts.weight.bold} size={theme.fonts.size.h4}>
              Recibe hoy un E-book de cuidado bucal de REGALO
            </Text>
          </Row>

          <Card
            p="20px 20px"
            background={theme.colors.white}
            style={{ margin: "20px 0 0 0" }}
          >
            <Row gap="5px" align="center">
              <Text
                color={theme.colors.springGreen}
                weight={theme.fonts.weight.extrabold}
                size={theme.fonts.size.h2}
                mr="20px"
              >
                RESEÑAS
              </Text>
              <StarFilled style={{ color: theme.colors.yellow }} />
              <StarFilled style={{ color: theme.colors.yellow }} />
              <StarFilled style={{ color: theme.colors.yellow }} />
              <StarFilled style={{ color: theme.colors.yellow }} />
              <StarFilled style={{ color: theme.colors.yellow }} />
            </Row>
            {testimonials.map((testimony) => {
              return (
                <Card
                  p="20px"
                  background="transparent"
                  align="left"
                  style={{ margin: "20px 0 10px 0" }}
                >
                  <Text
                    color={theme.colors.black}
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.h5}
                  >
                    {testimony.name}
                  </Text>
                  <Text
                    color={theme.colors.black}
                    weight={theme.fonts.weight.medium}
                    size={theme.fonts.size.h5}
                  >
                    {testimony.testimony}
                  </Text>
                </Card>
              );
            })}
          </Card>
          <Row m="30px 0 20px 0">
            <Text weight={theme.fonts.weight.bold} size={theme.fonts.size.h4}>
              Otros complementos estrellas de nuestro Kit
            </Text>
          </Row>
          <ImgWrapper
            background={glisterCompleteKit}
            width="300px"
            height="300px"
            radius="50px 0 50px 0"
            borderColor={theme.colors.white}
            alt="kit dental anti-sensibilidad"
          />
          <Row m="20px 0 20px 0" align="center">
            <Button
              size={theme.fonts.size.h3}
              weight={theme.fonts.weight.bold}
              onClick={() => openWhatsappToBuy(2)}
              width="100%"
              height="80px"
              m="10px"
              p="0 20px"
              background={theme.colors.red}
              radius="50px"
              animate={true}
            >
              QUIERO EL KIT COMPLETO
            </Button>
          </Row>
        </Col>
      </ImgBackgroundWrapper>
    </Div>
  );
};
