import { theme } from "../../styles/theme";

/* Informacion de los beneficios */
export const benefits = [
  {
    text: "- Fórmula patentada REMINACT™, dientes más sanos",
  },
  {
    text: "- Ayuda a disminuir las manchas (Dientes más blancos)",
  },
  {
    text: "- Fortalece el Esmalte dental",
  },
  {
    text: "- Limpia Efectivamente",
  },
  {
    text: "- Dile Adiós a la Sensibilidad",
  },
];

export const testimonials = [
  {
    name: "Manuela García",
    testimony:
      "La crema dental me ha parecido super buena. Poco a poco me elimina manchas por cafe y los veo más blancos",
  },
  {
    name: "Alejandro Gómez",
    testimony:
      "La crema dental me quitó la sensibilidad que otras cremas que usaba antes me dejaba,  la verdad Glister me ha gustado demasiado",
  },
  {
    name: "Natalia Pineda",
    testimony:
      "Es un kit que me ha ayudado demasiado para la sensibilidad dental ya que después de un tratamiento de ortodoncia mis dientes quedaron muy sensibles",
  },
  {
    name: "Nancy Espejo",
    testimony:
      "Es un kit muy bueno, hace poco me hice un diseño y me ha ayudado mucho a mantener el tono de mis dientes, además que ha reducido drásticamente mi sensibilidad. No la cambio por nada, me encanta",
  },
  {
    name: "Ana Muñoz",
    testimony:
      "Me gusta mucho, rinde demasiado, no me da sensibilidad y poco a poco los veo más blancos",
  },
];

export const getWhatsappLinks = (page) => {
  switch (page) {
    case "natalNatural":
      return {
        kit0: "https://wa.link/4zhksg",
        kit01: "https://wa.link/7qoexh", //TODO actualizar link
        kit02: "https://wa.link/get3ds", //TODO actualizar link
        kit1: "https://wa.link/gzbpc8",
        kit2: "https://wa.link/ile0vg",
      };
    case "lauraMarin":
      return {
        kit0: "https://wa.link/xpbvuw",
        kit01: "https://wa.link/ksr2wj",
        kit02: "https://wa.link/5zrown",
        kit1: "https://wa.link/4fux5n",
        kit2: "https://wa.link/2aeq4k",
      };
    default:
      return {
        kit0: "https://wa.link/y7v6zc",
        kit01: "https://wa.link/7qoexh",
        kit02: "https://wa.link/get3ds",
        kit1: "https://wa.link/qyt8zs",
        kit2: "https://wa.link/8z572h",
      };
  }
};
