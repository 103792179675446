import styled from "styled-components";
import { theme } from "../../styles/theme";

export const ImgWrapper = styled.div`
  height: ${({ height }) => height || "100px"};
  width: ${({ width }) => width || "100px"};
  background: ${({ background }) =>
    background
      ? `url(${background}) no-repeat`
      : `${theme.colors.green} 0% 0% no-repeat padding-box`};
  background-position: center center;
  background-size: cover;
  border-radius: ${({ radius }) => radius || "100px"};
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;
