import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import {
  Container,
  Row,
  Text,
  Div,
  Col,
  Card,
  Grid,
} from "../../styles/Common";
import { Button } from "../../components/Button";
import { theme } from "../../styles/theme";
import lecithinImg from "../../assets/images/lecithin.png";
import lecithinVideo from "../../assets/videos/lecithinDemo.mp4";
import { ImgBackgroundWrapper, ImgWrapper } from "./styles";
import { benefits, testimonials } from "./helpers";
import useFBApiConvertion from "../../core/hooks/useFBApiConvertion";
import { getUserIP } from "../../utilities/helpers";
import { StarFilled } from "@ant-design/icons";
import { openWhatsappChatKits } from "./helpers";

export const Lecithin = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: 768px)`,
  });

  const productsDataRef = useRef(null);

  const fbApiConvertion = useFBApiConvertion();

  const fbConvertionEvent = async (event) => {
    const ip = await getUserIP();
    const viewContentData = {
      data: [
        {
          event_name: event,
          event_time: Math.floor(new Date().getTime() / 1000),
          action_source: "website",
          user_data: {
            client_ip_address: ip,
            client_user_agent: navigator.userAgent,
            country: [
              "c330ec504d82c24fcd10be978fa74b8a3185a8df719604a85443bb9ca279f5a2",
            ],
          },
        },
      ],
    };
    fbApiConvertion.mutate(viewContentData);
  };

  useEffect(() => {
    fbConvertionEvent("ViewContent");
  }, []);

  const openWhatsappChat = () => {
    fbConvertionEvent("AddToCart");
    productsDataRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const openWhatsappToBuy = async (kit) => {
    fbConvertionEvent("InitiateCheckout");
    switch (kit) {
      case 1:
        window.open(`https://wa.link/bvo4ul`, "_blank");
        break;
      case 2:
        window.open(`https://wa.link/3s5ylu`, "_blank");
        break;
      case 3:
        window.open(`https://wa.link/gi6dl8`, "_blank");
        break;

      default:
        window.open(`https://wa.link/f3f1io`, "_blank");
        break;
    }
  };

  const getRandomUnitsSold = () => {
    return Math.floor(Math.random() * (24 - 12 + 1)) + 12;
  };
  const [unitsSold, setUnitsSold] = useState(getRandomUnitsSold());

  useEffect(() => {
    const interval = setInterval(() => {
      setUnitsSold((prevTotal) => prevTotal + 1);
    }, Math.floor(Math.random() * (20000 - 10000 + 1)) + 10000);

    return () => clearInterval(interval);
  }, []);

  return isMobile ? (
    <Div background={theme.colors.blue100}>
      <ImgBackgroundWrapper p="0 20px 0 20px">
        <Col align="center" width="100%">
          <Row m="50px 50px 0 50px">
            <Text
              color={theme.colors.orange500}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h2}
            >
              Descubre el Secreto de la Silueta Perfecta con
            </Text>
          </Row>
          <Row
            m="10px 50px"
            p="20px"
            style={{
              backgroundColor: theme.colors.orange500,
              borderRadius: "20px",
            }}
          >
            <Text
              color={theme.colors.white}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h2}
            >
              LECITINA E
            </Text>
          </Row>
          <Col m="50px 0 0 0" align="center" justify="center" gap="10px">
            <ImgWrapper
              background={lecithinImg}
              width="300px"
              height="350px"
              radius="50px 0 50px 0"
              alt="encapsulador de grasas"
            />
            <Text
              color={theme.colors.black}
              weight={theme.fonts.weight.medium}
              size={theme.fonts.size.h4}
              mt="10px"
            >
              Transforma tu cuerpo y recupera tu confianza con{" "}
              <span style={{ color: theme.colors.red }}>Lecitina E</span>, un
              suplemento dietario masticable con una fórmula revolucionaria
              diseñada para encapsular las grasas y evitar que acumules en las
              zonas caderas, muslos y abdomen.<br></br>
              Experimenta el poder de la lecitina en cada cápsula, tu aliada en
              la lucha contra el exceso de peso.
            </Text>

            <Text
              color={theme.colors.orange500}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h3}
              mt="10px"
            >
              COME SIN REMORDIMIENTOS
            </Text>
            <Row m="10px 0 10px 0" justify="center">
              <Button
                size={theme.fonts.size.h6}
                weight={theme.fonts.weight.bold}
                onClick={openWhatsappChat}
                width="100%"
                height="80px"
                m="10px"
                background={theme.colors.red}
                radius="50px"
                style={{ padding: "0 20px 0 20px" }}
              >
                APROVECHA LA PROMO
              </Button>
            </Row>
          </Col>
          <Row m="20px 0 0 0" align="center" justify="center" gap="40px">
            <Col p="0 20px" justify="center" style={{ gap: "5px" }}>
              <Text
                color={theme.colors.orange500}
                weight={theme.fonts.weight.extrabold}
                size={theme.fonts.size.h3}
                mb="10px"
              >
                Beneficios:
              </Text>
              {benefits.map((benefit, idx) => {
                return (
                  <Text
                    key={idx}
                    color={theme.colors.black}
                    weight={theme.fonts.weight.medium}
                    size={theme.fonts.size.h4}
                  >
                    {benefit.text}
                  </Text>
                );
              })}
            </Col>
          </Row>
          <Row p="40px 10px">
            <Text weight={theme.fonts.weight.bold} size={theme.fonts.size.h4}>
              ¿Frustrado para perder peso?
              <br></br>
              ¿Estreñimiento crónico?
              <br></br>
              Con{" "}
              <span style={{ color: theme.colors.orange500 }}>
                Lecitina E,
              </span>{" "}
              dejaremos atrás esos dolores y te ayudaremos a alcanzar tus metas
              de bienestar.
            </Text>
          </Row>
          <Row justify="center">
            <Text
              color={theme.colors.orange500}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h3}
              mt="10px"
            >
              ¡¡Mira como funciona!!
            </Text>
          </Row>
          <Row>
            <Div
              width="250px"
              height="250px"
              radius="50px"
              style={{
                position: "relative",
                overflow: "hidden",
              }}
            >
              <video
                width="100%"
                height="100%"
                style={{ objectFit: "cover" }}
                controls
              >
                <source src={lecithinVideo} type="video/mp4" />
                Tu navegador no soporta el elemento de video.
              </video>
            </Div>
          </Row>
          <Col
            justify="center"
            align="center"
            m="20px 0 40px 0"
            ref={productsDataRef}
          >
            <Text
              color={theme.colors.orange}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h3}
            >
              {unitsSold} Unidades
            </Text>
            <Text
              color={theme.colors.black}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h3}
            >
              vendidas la ultima hora
            </Text>
          </Col>

          <Card p="20px 20px" background={theme.colors.white}>
            <Col gap="20px" justify="space-around" align="center">
              <Col justify="center" align="center">
                <Text
                  color={theme.colors.orange500}
                  weight={theme.fonts.weight.extrabold}
                  size={theme.fonts.size.h3}
                  mt="10px"
                >
                  1 unidad: $85.000
                </Text>
                <Button
                  size={theme.fonts.size.h4}
                  weight={theme.fonts.weight.bold}
                  onClick={() => openWhatsappToBuy(1)}
                  width="100%"
                  height="80px"
                  m="10px"
                  background={theme.colors.red}
                  radius="50px"
                  style={{ padding: "0 20px 0 20px" }}
                >
                  Pedir
                </Button>
              </Col>
              <Col
                justify="center"
                align="center"
                style={{
                  gap: "10px",
                  borderColor: theme.colors.orange500,
                  borderWidth: "2px",
                  borderRadius: "20px",
                  borderStyle: "solid",
                  padding: "10px",
                }}
              >
                <Text
                  color={theme.colors.black}
                  weight={theme.fonts.weight.extrabold}
                  size={theme.fonts.size.h3}
                >
                  2 unidades:
                  <span style={{ textDecoration: "line-through" }}>
                    {" "}
                    $170.000
                  </span>{" "}
                  <br></br>
                  $154.500
                </Text>
                <Text
                  color={theme.colors.orange500}
                  weight={theme.fonts.weight.extrabold}
                  size={theme.fonts.size.h3}
                >
                  con descuento
                </Text>
                <Button
                  size={theme.fonts.size.h4}
                  weight={theme.fonts.weight.bold}
                  onClick={() => openWhatsappToBuy(2)}
                  width="100%"
                  height="80px"
                  background={theme.colors.red}
                  radius="50px"
                  style={{ padding: "0 20px 0 20px" }}
                >
                  Pedir
                </Button>
              </Col>
              <Col
                justify="center"
                align="center"
                style={{
                  gap: "10px",
                  borderColor: theme.colors.orange500,
                  borderWidth: "2px",
                  borderRadius: "20px",
                  borderStyle: "solid",
                  padding: "10px",
                }}
              >
                <Text
                  color={theme.colors.black}
                  weight={theme.fonts.weight.extrabold}
                  size={theme.fonts.size.h3}
                >
                  3 unidades:
                  <span style={{ textDecoration: "line-through" }}>
                    {" "}
                    $255.000
                  </span>{" "}
                  <br></br>
                  $199.000
                </Text>
                <Text
                  color={theme.colors.orange500}
                  weight={theme.fonts.weight.extrabold}
                  size={theme.fonts.size.h3}
                >
                  gran descuento
                </Text>
                <Button
                  size={theme.fonts.size.h4}
                  weight={theme.fonts.weight.bold}
                  onClick={() => openWhatsappToBuy(3)}
                  width="100%"
                  height="80px"
                  background={theme.colors.red}
                  radius="50px"
                  style={{ padding: "0 20px 0 20px" }}
                >
                  Pedir
                </Button>
              </Col>
            </Col>
          </Card>
          <Row m="40px 0 0 0">
            <Text
              color={theme.colors.orange500}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h3}
              mt="20px"
            >
              PAGO CONTRA-ENTREGA
            </Text>
          </Row>
          <Row m="0 0 40px 0">
            <Text weight={theme.fonts.weight.bold} size={theme.fonts.size.h4}>
              ¡Descubre una nueva versión de ti mismo con Lecitina E! ¡Ordénala
              ahora y comienza tu viaje hacia un cuerpo más saludable y una
              confianza renovada!
            </Text>
          </Row>

          <Card
            p="20px 20px"
            background={theme.colors.white}
            style={{ margin: "20px 0 0 0" }}
          >
            <Row gap="5px" align="center">
              <Text
                color={theme.colors.orange500}
                weight={theme.fonts.weight.extrabold}
                size={theme.fonts.size.h3}
                mr="20px"
              >
                RESEÑAS
              </Text>
              <StarFilled style={{ color: theme.colors.yellow }} />
              <StarFilled style={{ color: theme.colors.yellow }} />
              <StarFilled style={{ color: theme.colors.yellow }} />
              <StarFilled style={{ color: theme.colors.yellow }} />
              <StarFilled style={{ color: theme.colors.yellow }} />
            </Row>
            {testimonials.map((testimony, idx) => {
              return (
                <Card
                  key={idx}
                  p="20px"
                  background="transparent"
                  align="left"
                  style={{ margin: "20px 0 10px 0" }}
                >
                  <Text
                    color={theme.colors.black}
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.h5}
                  >
                    {testimony.name}
                  </Text>
                  <Text
                    color={theme.colors.black}
                    weight={theme.fonts.weight.medium}
                    size={theme.fonts.size.h5}
                  >
                    {testimony.testimony}
                  </Text>
                </Card>
              );
            })}
          </Card>
          <Row m="50px 0 0 0" align="center" justify="center" gap="40px">
            <Col>
              <Text
                color={theme.colors.orange500}
                weight={theme.fonts.weight.extrabold}
                size={theme.fonts.size.h4}
                mt="20px"
              >
                Características del Producto:
              </Text>
              <Text
                color={theme.colors.black}
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.h5}
                mt="20px"
              >
                Potente Fórmula Antioxidante: Lecitina E cuenta con una
                combinación única de miel, algarrobo y vitamina E para potenciar
                sus beneficios antioxidantes.
              </Text>
              <Text
                color={theme.colors.black}
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.h5}
                mt="20px"
              >
                Cápsulas de Alta Calidad: Fabricadas con los más altos
                estándares, nuestras cápsulas aseguran una dosificación precisa
                y una absorción óptima.
              </Text>
            </Col>
          </Row>
          <Row m="20px 0 20px 0" justify="center">
            <Button
              size={theme.fonts.size.h4}
              weight={theme.fonts.weight.bold}
              onClick={() => openWhatsappToBuy(0)}
              width="100%"
              height="80px"
              m="10px"
              p="0 20px"
              background={theme.colors.red}
              radius="50px"
            >
              QUIERO MI LECITINA
            </Button>
          </Row>
        </Col>
      </ImgBackgroundWrapper>
    </Div>
  ) : (
    <Div background={theme.colors.blue100}>
      <ImgBackgroundWrapper>
        <Col align="center" width="100%">
          <Row m="50px 200px 0 200px">
            <Text
              color={theme.colors.orange500}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h1}
            >
              Descubre el Secreto de la Silueta Perfecta con
            </Text>
          </Row>
          <Row
            m="10px 300px 0 300px"
            p="20px"
            style={{
              backgroundColor: theme.colors.orange500,
              borderRadius: "20px",
            }}
          >
            <Text
              color={theme.colors.white}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h1}
            >
              LECITINA E
            </Text>
          </Row>
          <Row m="50px 0 0 0" align="center" justify="center" gap="40px">
            <ImgWrapper
              background={lecithinImg}
              width="500px"
              height="550px"
              radius="50px 0 50px 0"
              alt="encapsulador de grasas"
            />
            <Col width="400px" align="left" style={{ gap: "5px" }}>
              <Text
                color={theme.colors.black}
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.h4}
                mt="20px"
              >
                Transforma tu cuerpo y recupera tu confianza con{" "}
                <span style={{ color: theme.colors.red }}>Lecitina E</span>, un
                suplemento dietario masticable con una fórmula revolucionaria
                diseñada para encapsular las grasas y evitar que acumules en las
                zonas caderas, muslos y abdomen.<br></br>
                Experimenta el poder de la lecitina en cada cápsula, tu aliada
                en la lucha contra el exceso de peso.
              </Text>

              <Text
                color={theme.colors.orange500}
                weight={theme.fonts.weight.extrabold}
                size={theme.fonts.size.h3}
                mt="20px"
              >
                COME SIN REMORDIMIENTOS
              </Text>
              <Row m="30px 0 10px 0">
                <Button
                  size={theme.fonts.size.h4}
                  weight={theme.fonts.weight.bold}
                  onClick={openWhatsappChat}
                  width="100%"
                  height="80px"
                  m="10px"
                  background={theme.colors.red}
                  radius="50px"
                  style={{ padding: "0 20px 0 20px" }}
                >
                  APROVECHA LA PROMO
                </Button>
              </Row>
            </Col>
          </Row>
          <Row m="20px 0 0 0" align="center" justify="center" gap="40px">
            <Col p="0 20px" justify="center" style={{ gap: "5px" }}>
              <Text
                color={theme.colors.orange500}
                weight={theme.fonts.weight.extrabold}
                size={theme.fonts.size.h2}
                mb="10px"
              >
                Beneficios:
              </Text>
              {benefits.map((benefit, idx) => {
                return (
                  <Text
                    key={idx}
                    color={theme.colors.black}
                    weight={theme.fonts.weight.medium}
                    size={theme.fonts.size.h3}
                  >
                    {benefit.text}
                  </Text>
                );
              })}
            </Col>
          </Row>
          <Row p="40px">
            <Text weight={theme.fonts.weight.medium} size={theme.fonts.size.h4}>
              ¿Te sientes frustrado por los esfuerzos infructuosos para perder
              peso?
              <br></br>
              ¿El estreñimiento y la acumulación de grasa te han robado la
              confianza?
              <br></br>
              Con{" "}
              <span style={{ color: theme.colors.orange500 }}>
                Lecitina E,
              </span>{" "}
              dejaremos atrás esos dolores y te ayudaremos a alcanzar tus metas
              de bienestar.
            </Text>
          </Row>
          <Row justify="center" style={{ gap: "15px" }}>
            <Text
              color={theme.colors.orange500}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h2}
              mt="10px"
            >
              ¡¡Mira como funciona!!
            </Text>
          </Row>
          <Row>
            <Div
              width="300px"
              height="300px"
              radius="50px"
              style={{
                position: "relative",
                overflow: "hidden",
              }}
            >
              <video
                width="100%"
                height="100%"
                style={{ objectFit: "cover" }}
                controls
              >
                <source src={lecithinVideo} type="video/mp4" />
                Tu navegador no soporta el elemento de video.
              </video>
            </Div>
          </Row>
          <Row
            justify="center"
            align="center"
            m="20px 0 40px 0"
            style={{ gap: "15px" }}
            ref={productsDataRef}
          >
            <Text
              color={theme.colors.orange}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h1}
            >
              {unitsSold} Unidades
            </Text>
            <Text
              color={theme.colors.black}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h1}
            >
              vendidas la ultima hora
            </Text>
          </Row>

          <Card p="20px 20px" background={theme.colors.white}>
            <Row gap="20px" justify="space-around" align="center">
              <Col>
                <Text
                  color={theme.colors.orange500}
                  weight={theme.fonts.weight.extrabold}
                  size={theme.fonts.size.h2}
                  mt="10px"
                >
                  1 unidad: $85.000
                </Text>
                <Button
                  size={theme.fonts.size.h3}
                  weight={theme.fonts.weight.bold}
                  onClick={() => openWhatsappToBuy(1)}
                  width="100%"
                  height="80px"
                  m="10px"
                  background={theme.colors.red}
                  radius="50px"
                  style={{ padding: "0 20px 0 20px" }}
                >
                  Pedir
                </Button>
              </Col>
              <Col
                justify="center"
                align="center"
                style={{
                  gap: "15px",
                  borderColor: theme.colors.orange500,
                  borderWidth: "2px",
                  borderRadius: "20px",
                  borderStyle: "solid",
                  padding: "10px",
                }}
              >
                <Text
                  color={theme.colors.black}
                  weight={theme.fonts.weight.extrabold}
                  size={theme.fonts.size.h2}
                >
                  2 unidades:
                  <span style={{ textDecoration: "line-through" }}>
                    {" "}
                    $170.000
                  </span>{" "}
                  <br></br>
                  $154.500
                </Text>
                <Text
                  color={theme.colors.orange500}
                  weight={theme.fonts.weight.extrabold}
                  size={theme.fonts.size.h2}
                >
                  con descuento
                </Text>
                <Button
                  size={theme.fonts.size.h3}
                  weight={theme.fonts.weight.bold}
                  onClick={() => openWhatsappToBuy(2)}
                  width="100%"
                  height="80px"
                  m="10px"
                  background={theme.colors.red}
                  radius="50px"
                  style={{ padding: "0 20px 0 20px" }}
                >
                  Pedir
                </Button>
              </Col>
              <Col
                justify="center"
                align="center"
                style={{
                  gap: "15px",
                  borderColor: theme.colors.orange500,
                  borderWidth: "2px",
                  borderRadius: "20px",
                  borderStyle: "solid",
                  padding: "10px",
                }}
              >
                <Text
                  color={theme.colors.black}
                  weight={theme.fonts.weight.extrabold}
                  size={theme.fonts.size.h2}
                >
                  3 unidades:
                  <span style={{ textDecoration: "line-through" }}>
                    {" "}
                    $255.000
                  </span>{" "}
                  <br></br>
                  $199.000
                </Text>
                <Text
                  color={theme.colors.orange500}
                  weight={theme.fonts.weight.extrabold}
                  size={theme.fonts.size.h2}
                >
                  gran descuento
                </Text>
                <Button
                  size={theme.fonts.size.h3}
                  weight={theme.fonts.weight.bold}
                  onClick={() => openWhatsappToBuy(3)}
                  width="100%"
                  height="80px"
                  m="10px"
                  background={theme.colors.red}
                  radius="50px"
                  style={{ padding: "0 20px 0 20px" }}
                >
                  Pedir
                </Button>
              </Col>
            </Row>
          </Card>
          <Row m="40px 0 0 0">
            <Text
              color={theme.colors.orange500}
              weight={theme.fonts.weight.extrabold}
              size={theme.fonts.size.h2}
              mt="20px"
            >
              PAGO CONTRA-ENTREGA
            </Text>
          </Row>
          <Row m="0 0 40px 0">
            <Text weight={theme.fonts.weight.bold} size={theme.fonts.size.h4}>
              ¡Descubre una nueva versión de ti mismo con Lecitina E! ¡Ordénala
              ahora y comienza tu viaje hacia un cuerpo más saludable y una
              confianza renovada!
            </Text>
          </Row>

          <Card
            p="20px 20px"
            background={theme.colors.white}
            style={{ margin: "20px 0 0 0" }}
          >
            <Row gap="5px" align="center">
              <Text
                color={theme.colors.orange500}
                weight={theme.fonts.weight.extrabold}
                size={theme.fonts.size.h2}
                mr="20px"
              >
                RESEÑAS
              </Text>
              <StarFilled style={{ color: theme.colors.yellow }} />
              <StarFilled style={{ color: theme.colors.yellow }} />
              <StarFilled style={{ color: theme.colors.yellow }} />
              <StarFilled style={{ color: theme.colors.yellow }} />
              <StarFilled style={{ color: theme.colors.yellow }} />
            </Row>
            {testimonials.map((testimony, idx) => {
              return (
                <Card
                  key={idx}
                  p="20px"
                  background="transparent"
                  align="left"
                  style={{ margin: "20px 0 10px 0" }}
                >
                  <Text
                    color={theme.colors.black}
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.h5}
                  >
                    {testimony.name}
                  </Text>
                  <Text
                    color={theme.colors.black}
                    weight={theme.fonts.weight.medium}
                    size={theme.fonts.size.h5}
                  >
                    {testimony.testimony}
                  </Text>
                </Card>
              );
            })}
          </Card>
          <Row m="50px 0 0 0" align="center" justify="center" gap="40px">
            <Col>
              <Text
                color={theme.colors.orange500}
                weight={theme.fonts.weight.extrabold}
                size={theme.fonts.size.h3}
                mt="20px"
              >
                Características del Producto:
              </Text>
              <Text
                color={theme.colors.black}
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.h4}
                mt="20px"
              >
                Potente Fórmula Antioxidante: Lecitina E cuenta con una
                combinación única de miel, algarrobo y vitamina E para potenciar
                sus beneficios antioxidantes.
              </Text>
              <Text
                color={theme.colors.black}
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.h4}
                mt="20px"
              >
                Cápsulas de Alta Calidad: Fabricadas con los más altos
                estándares, nuestras cápsulas aseguran una dosificación precisa
                y una absorción óptima.
              </Text>
            </Col>
          </Row>
          <Row m="20px 0 20px 0" align="center">
            <Button
              size={theme.fonts.size.h3}
              weight={theme.fonts.weight.bold}
              onClick={() => openWhatsappToBuy(0)}
              width="100%"
              height="80px"
              m="10px"
              p="0 20px"
              background={theme.colors.red}
              radius="50px"
            >
              QUIERO MI LECITINA
            </Button>
          </Row>
        </Col>
      </ImgBackgroundWrapper>
    </Div>
  );
};
