import { useMutation } from "react-query";
import axios from "axios";

const useFBApiConvertion = () => {
  const apiVersion = "v18.0";
  const token = process.env.REACT_APP_FACEBOOK_TOKEN;
  const pixelId = process.env.REACT_APP_FACEBOOK_PIXEL_ID;

  const axiosClient = axios.create({
    baseURL: `https://graph.facebook.com/${apiVersion}/${pixelId}`,
  });
  const mutation = useMutation((formData) => {
    return axiosClient.post(`/events?access_token=${token}`, formData);
  });
  return mutation;
};
export default useFBApiConvertion;
